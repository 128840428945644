import { useQuery } from '@tanstack/vue-query'
import { computed } from 'vue'

export function useTaxRules(taxTypes = null) {
    const { useApiSearch } = useApiFactory('tax-rules')
    const { execute } = useApiSearch({}, false)

    const getTaxRules = () => {
        const unrefTaxTypes = unref(taxTypes)
        let filter = {}
        if (unrefTaxTypes) {
            filter = {
                category: ['in', unref(taxTypes)],
            }
        }

        return execute({
            all: true,
            filter,
        })
    }

    const queryKey = computed(() => {
        const unrefTaxTypes = unref(taxTypes)
        if (!unrefTaxTypes) {
            return ['tax_rules']
        }

        return ['tax_rules', ...unrefTaxTypes]
    })

    const taxRules = computed(() => data.value || [])
    const { data, isLoading } = useQuery({
        queryKey,
        queryFn: () => getTaxRules(),
        staleTime: Infinity,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })

    return {
        taxRules,
        getTaxRules,
        isLoading,
    }
}
